import React, { useState } from "react"
import Head from "../components/head"
import Layout from "../components/layout"
import * as blogStyles from "../components/blog.module.scss"

// sticker images

import vanta from "../img/vanta.png"
import ivory from "../img/ivory.png"
import gold from "../img/gold.png"
import amber from "../img/amber.png"
import pine from "../img/pine.png"
import purple from "../img/purple.png"
import daffodil from "../img/daffodil.png"
import blush from "../img/blush.png"

const Form = () => {
  const [stickerState, setStickerState] = useState(vanta)

  return (
    <Layout>
      <Head title="Form" />
      <div className={`${blogStyles.blogDiv}`}>
        <form
          name="colorweek-form"
          method="post"
          data-netlify="true"
          netlify-honeypot="bot-field"
          className="colorWeekForm"
          // data-netlify-recaptcha="true"
          // action="/"
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <h2> Newsletter Sign Up!!</h2>
              <input type="hidden" name="form-name" value="colorweek-form" />
              {/*
            <p class="hidden">
              <label>
                Don’t fill this out if you’re human: <input name="bot-field" />
              </label>
            </p>
            */}
              <p>
                <label>
                  First Name: <input type="text" name="fname" />
                </label>
              </p>
              <p>
                <label>
                  Last Name: <input type="text" name="lname" />
                </label>
              </p>
              <p>
                <label>
                  Email: <input type="text" name="email" />
                </label>
              </p>
              {/* DEPRECATED - sticker for colorweek  */}
              {/*
              <p>
                <label>
                  Free Sticker:{" "}
                  <select
                    id="sticker"
                    name="sticker"
                    onChange={e => {
                      const selectedSticker = e.target.value
                      setStickerState(selectedSticker)
                    }}
                  >
                    <option value={vanta}>VANTA</option>
                    <option value={ivory}>IVORY</option>
                    <option value={gold}>GOLD</option>
                    <option value={amber}>AMBER</option>
                    <option value={pine}>PINE</option>
                    <option value={purple}>PURPLE</option>
                    <option value={daffodil}>DAFFODIL</option>
                    <option value={blush}>BLUSH</option>
                  </select>
                </label>
              </p>
              <p>
                <label>
                  {" "}
                  Address #1: <input type="streetOne" name="streetOne" />
                </label>
              </p>
              <p>
                <label>
                  {" "}
                  Address #2: <input type="streetTwo" name="streetTwo" />
                </label>
              </p>
               */}
              <p>
                <label>
                  {" "}
                  City: <input type="city" name="inputCity" />
                </label>
              </p>
              <p>
                <label>
                  {" "}
                  State:{" "}
                  <input
                    type="state"
                    name="inputState"
                    style={{ width: "40px" }}
                  />
                </label>
                {/*
                <label>
                  {" "}
                  Zip:{" "}
                  <input type="zip" name="inputZip" style={{ width: "40px" }} />
                </label>
              */}
              </p>
              <div data-netlify-recaptcha="true"></div>
              <button
                className="unusualButtonMail"
                type="submit"
                style={{ fontSize: "10pt" }}
              >
                Subscribe
              </button>
            </div>
            {/*
            <div>
              <img src={stickerState} className="stickerImage" alt="sticker"/>
            </div>
            */}
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default Form
